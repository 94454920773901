.logo, .header-text {
    margin: .5rem;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    transition: .2s ease-in-out;
}


.logo {
    width: 10rem;
    height: 10rem;
    background-image: url(./images/logo.svg);
    filter: invert();
}
.header-text {
    width: 60rem;
    height: 10rem;
    background-image: url(./images/header.svg);
}