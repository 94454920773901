.sub-header {
    color: white;
    font-family: Raleway;
    letter-spacing: 15px;
    font-size: 2rem;
    opacity: .8;
}

.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(65%);
    width: 100vw;
    height: 130vh;
    background-image: url(./images/spining-books.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    animation-name: spinning;
    animation-duration: 150s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes spinning {
    from {
      transform: translateY(65%) rotate(0deg) ;
    }
  
    to {
      transform:  translateY(65%) rotate(360deg);
    }
  }