@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap');

* {
    box-sizing: border-box;
    /* transition: .2s ease-in-out; */
    margin: 0;
}

html {
    font-size: 18px;
}

.body {
    background-color: #000000;
    margin: 0;
    font-weight: 400;
    line-height: 160%;
    height: 100vh;
    width: 100vw;
    background-image: url(./images/bg.svg);
    overflow: hidden;
    position: relative;
    text-align: center;
}

.body::after {
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 101vw;
    height: 100vh;
    content: '';
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 56.77%, #000000 100%);
    z-index: 10;
}

.container {
    width: 85%;
    margin: auto;
}

.fx-scattered {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.fx-centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.fx-even {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.fx-around {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.fx-shrink {
    flex-shrink: 0;
}

.fx-col {
    flex-direction: column;
}

.fx-start-v {
    align-items: flex-start;
}

.fx-start-h {
    justify-content: flex-start;
}

.fx-end-v {
    align-items: flex-end;
}

.fx-end-h {
    justify-content: flex-end;
}

.fx-stretch {
    align-items: stretch;
}

.fx-wrap {
    flex-wrap: wrap;
}

/* widths style */

.fit-container {
    width: 100%;
}

.half-container {
    width: 50%;
}

.qd-container {
    width: 42rem;
}

.fit-content {
    width: fit-content;
}

/* white space style */

.box-pad-h {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.box-pad-v {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.box-pad-h-m {
    padding-left: 1rem;
    padding-right: 1rem;
}

.box-pad-v-m {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.box-pad-h-s {
    padding-left: .5rem;
    padding-right: .5rem;
}

.box-pad-v-s {
    padding-top: .5rem;
    padding-bottom: .5rem;
}

.box-marg {
    margin: 0 auto 3rem;
}

.box-marg-s {
    margin: 0 auto 1rem;
}

.box-marg-full {
    margin: 3rem auto;
}


a {
    text-decoration: none;
    color: var(--black);
}

@media screen and (max-width: 1200px) {
    html {
        font-size: 16px;
    }
}

@media screen and (max-width: 1000px) {
    html {
        font-size: 14px;
    }
    .fx-start-h {
        justify-content: center;
    }
    
}

@media screen and (max-width: 900px) {
    html {
        font-size: 12px;
    }
    .footer {
        bottom: 10%;
    }
}

@media screen and (max-width: 700px) {
    .sub-header {
        font-size: 1rem;
        letter-spacing: 8px;
    }
    .header-text {
        width: 30rem;
        height: 5rem;
    }
    .footer {
        bottom: 10%;
        left: -15%;
        width: 130vw;
    }
    
}
@media screen and (max-width: 400px) {
    .sub-header {
        font-size: 1rem;
        letter-spacing: 8px;
    }
    .header-text {
        width: 30rem;
        height: 5rem;
    }
    
}